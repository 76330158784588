import styles from './Portafolio.module.css'
import cabin from '../assets/cabin.jpg'
import cake from '../assets/cake.jpg'
import circus from '../assets/circus.jpg'
import game from '../assets/game.jpg'
import safe from '../assets/safe.jpg'
import submarine from '../assets/submarine.jpg'
import { Brush } from 'react-bootstrap-icons';

const Portafolio = () => {

    return (
       <section className={styles.portafolioContainer}>
        <h4 className={styles.portafolioTitle}>Portafolio</h4>
        
        <Brush className={styles.portafolioStart}/>
        <section className={styles.portafolioContentImages}>
        <img className={styles.portafolioImg} src={cabin} alt='cabin-img' />
              <img className={styles.portafolioImg} src={cake} alt='cake-img' />
        <img className={styles.portafolioImg} src={circus} alt='circus-img' />
        <img className={styles.portafolioImg} src={game} alt='game-img' />
        <img className={styles.portafolioImg} src={safe} alt='safe-img' />
        <img className={styles.portafolioImg} src={submarine} alt='submarine-img' />
        </section>
        </section>

    )
}

export { Portafolio };