import styles from './Navbar.module.css'
import { Portafolio } from './Portafolio'
import { About } from './About'
import { Contactme } from './Contactme'



const Navbar = () => {
    
    return (
       <section className={styles.navContainer}>
        <nav  className={styles.navMenu} onClick={ < Portafolio /> } > Portafolio </nav> 
        <nav className={styles.navMenu} onClick={ < About /> } >About</nav>
        <nav className={styles.navMenu} onClick= { < Contactme /> }> Contact </nav> 
       

     </section>

    )
}

export { Navbar };

