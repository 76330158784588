import styles from './Startbootstrap.module.css'
import profile from '../assets/profile.png'
import { Flower3 } from 'react-bootstrap-icons';

const Startbootstrap = () => {

    return (
       <section className={styles.startContainer}>
        <img className={styles.startProfile} src={profile} alt='logo-icon' />
        <h1 className={styles.startTitle}> Welcome</h1>
        <h3 className={styles.startName}> Im Liliana Hdez </h3>
        <Flower3 className={styles.startIcon}/>
        <p className={styles.startParagraph}> Web Developer  </p>
       </section>

    )
}

export { Startbootstrap };