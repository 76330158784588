import styles from './Footer.module.css'

const Footer = () => {

    return (
       <section className={styles.footerContainer}>
        <h3 className={styles.footerTitle}>CDMX MX</h3>
       </section>

    )
}

export { Footer };