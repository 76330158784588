import './App.css';
import { Navbar } from './components/Navbar'
import { Startbootstrap } from './components/Startbootstrap';
import { Portafolio } from './components/Portafolio';
import { About } from './components/About';
import { Contactme } from './components/Contactme';
import { Footer } from './components/Footer';

import Container from 'react-bootstrap/Container';
/* import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
 */

function App() {
  return (
    <Container className="container">
     <section><Navbar /></section>  
     <section><Startbootstrap /></section>  
     <section><Portafolio /></section>  
     <section><About /></section>  
     <section><Contactme /></section> 
     <section><Footer /></section>
    </Container>
  );
};

export default App;
