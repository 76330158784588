import styles from './About.module.css'
import { PersonSquare } from 'react-bootstrap-icons';

const About = () => {

    return (
       <section className={styles.aboutContainer}>
        <h1 className={styles.aboutTitle}>About</h1>
        <PersonSquare className={styles.aboutStart}/>
        <section className={styles.aboutContainerParagraph}>
        <p className={styles.aboutParagraph1}>
        Im an Engieneer and Frond-End Developer 
        <br></br>
        with work experience technology sector 
        <br></br>
        oriented to customer service.
        <br></br>
        My carrer has allowed me to play different roles, 
        <br></br>
        which have taught me the importance of 
        <br></br>
        end-user satisfaction.
        <br></br>
        I learned that the end user may have different
                
        </p>
        <p className={styles.aboutParagraph2}>
        interest  to which I wish to provide a quality service 
        <br></br>
        or product.
        <br></br>
         My goal is to be part of a company where I can contribute
        <br></br>
        and develop my skills for the benefit of its culture and objectives.
        <br></br>
        Today as a Front-End Developer I build web pages that 
        <br></br>
        facilitate and improve the life of the end user.
             
        </p>
        </section>
        
        
        </section>

    )
}

export { About };