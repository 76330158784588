import styles from './Contactme.module.css'
import { Mailbox2, Github, Linkedin} from 'react-bootstrap-icons';

const Contactme = () => {

    return (
       <section className={styles.contactContainer}>
       <h2 className={styles.contactTitle}> Contact</h2>
      
       <p className={styles.contactForm}>
         <Mailbox2 className={styles.contactStar}/>   lylyzyta@gmail.com
        <br></br>
        <Github className={styles.contactStar}/>   https://github.com/lylyzyta
        <br></br>
        <Linkedin className={styles.contactStar}/> https://www.linkedin.com/in/lilianahezcastelán/
       </p>
        
       </section>

    )
}

export { Contactme };